
:root {
  --space-3xs: clamp(0.31rem, calc(0.21rem + 0.49vw), 0.56rem);
  --space-2xs: clamp(0.56rem, calc(0.42rem + 0.73vw), 0.94rem);
  --space-xs: clamp(0.88rem, calc(0.66rem + 1.10vw), 1.44rem);
  --space-s: clamp(1.13rem, calc(0.83rem + 1.46vw), 1.88rem);
  --space-m: clamp(1.69rem, calc(1.25rem + 2.20vw), 2.81rem);
  --space-l: clamp(3.38rem, calc(2.50rem + 4.39vw), 5.63rem);
  --space-xl: clamp(4.50rem, calc(3.33rem + 5.85vw), 7.50rem);
  --space-2xl: clamp(5.63rem, calc(4.16rem + 7.32vw), 9.38rem);
  --space-3xl: clamp(6.75rem, calc(4.99rem + 8.78vw), 11.25rem);

}
.pdeu{
  padding-top: 22px;
  height: 7rem;
}
a{
  text-decoration: none;
  color: black;
}




@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@media only screen and (min-device-width: 481px) {
  .pdeu{
    margin-top: 3%;
  }
.container{
  width: 100%;
}
.banner {
  
  display:flex;
  width:100%;
  height:100%;

}

#background{
  background-image:url("../assets/home/ban.svg");   
  width: 100%;
  background-repeat: no-repeat;
  background-size:cover;
  height: 105vh;
}
#banner_btn:hover{
  font-size: 1.1em;
  font-weight: 700;
}
#prod_title_tag{
  text-align: center;
}
.products_solutions {
  width: 100%;
  padding-bottom: 3%;
  
background:#fafafa;
 
}

.sprayx{
  position: absolute;
  top: 11em;
  right: 9em;
  width: 34%;
}

#banner_title{
  position: absolute;
  top: 1.5em;
  left: 5%;
font-size: 2.6em;
text-align: center;
}

#tagline{
  position: absolute;
  top: 10em;
  left: 6.5%;
  font-size:  1.4em;
  font-weight: 500;
}

#banner_btn{
  position: relative;
  cursor: pointer;
  width: 16rem;
  height: 2.2em;
 top: 72%;
 left: 5%;
 bottom: 10%;
 margin-bottom: 10%;
  font-size: 1em;
  border:none;
  font-weight: 600;
  background: #F6F6F6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

button:hover{
  box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.25);
}

.values_customers{
  height: 586px;
  padding-top: 3em;
}

#val_img{
  display: flex;
  flex-direction: row; 
  margin-left: 8rem;
  margin-top: 2em;
  width: 38%;
}

#val_title{
  display: grid;
  text-align: center;
  margin-top: 0px;
  font-size: var(--space-s);
  color: #0F2492;
}

#blue-line{
  width: 70px;
  border: 2px solid #273195;
}

#learn-blue-line{
  width: 70px;
  border: 2px solid #273195;
  margin-left: 3%;

}

#cust-blue-line{
  width: 70px;
  position: relative;
  top: 0%;
  left: 7em;
  border: 2px solid #273195;
}
.val_cust_content,.cust_content{
  display: flex;
  flex-direction: row;
 
}

#val_tag{
 width: 70%;
  font-size: var(--space-xs);
  margin-right: 5rem;
  margin-top: 2em;
  color: black;
  text-align: left;
  line-height: 1.7em;
}

.val_cust_content h3{
 
  font-size: var(--space-xs);
  margin-right: 5rem;
  margin-top: 2em;
  color: black;
  text-align: left;
  line-height: 1.7em;
}

#val_content{
  margin-left: 7%;
  margin-top: 4%;
}

.val_cust_content p{
  
  line-height: 1.7em;

  font-weight: 500;
  width: 70%;
  font-size:  1em;
  text-align: left;
}

.learn_more{


font-weight: 700;
font-size:  .9em;
color: #0F2492;
}

.cust_learn_more{
margin-bottom: 7%;
font-weight: 700;
font-size:   var(--space-1xs);
color: #0F2492;
}
.val_cust_content h2{
  font-size: 36;
  font-weight: 780;
  margin-left: 52%;
  margin-top: -17em;
  color: black;
  text-align: left;
  line-height: 1.7em;
}

#cust_img{
  display: flex;
  flex-direction: row;
  width: 38%;
  margin-left: 9rem;
  margin-top: 1em;
  
}

#cust_p{
  

  line-height: 1.7em;
  margin-top: -22em;
  margin-right: 10em;
  font-weight: 500;
  font-size:  var(--space-1xs);
  text-align: left;
 }



.careers{
  display: flex;
  flex-direction: row;
  background: #EFEFEF;
  justify-content: space-between;
}



#career_card{
  margin: 6%;
  margin-left: 8%;
  width: 28%;
  padding-bottom: 2%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  }



  #career_title1{  
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0F2492;
  }
  
  #career_title2{
    margin-left: 6%;
    font-size: 2.2em;
    text-align: left;
    letter-spacing: 4px;
    width: 80%;
  }
  
  #career_line{
    width: 100px;  
    border: 2px solid #273195;
  }
  
  #careers_content{
    text-align: left;
    padding-left: 30px;
    width: 80%;
    font-size: 1.2em;
    line-height: 32px;
    
  }



  #career_learn{
    font-weight: 700;
  font-size:1.1em;

  color: #0F2492;
  margin-left: 31%;
  margin-right: 26%;
  } 
  

#careers_img{
  width:54%;
}



  
#space{
  width:"2000px";
  height:"12rem";
}


#prod_soln_title{
  margin-top: 3%;
  display: grid;
  text-align: center;
 
  font-size: var(--space-s);
  color: #0F2492;
}

.products_solutions p{
   font-size: var(--space-1xs);
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-weight: 450;
   text-align: center;
}

.card-list{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
 
}
.card{
width: 21%;
margin-top: 2rem;
background: #fff;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 12px;
}
.card:hover {
  transform: scale(1.08);
}
.card img{
  width: 94%;
  margin: 3%;
}

.card p{
  text-align: left;
  width: 90%;
  margin-top: 4%;
  margin-left: 6%;
  text-transform: none;
  font-weight: 500;
   font-size: max(10px,var(--space-2xs));

}


.card34_title{
  font-weight: 700;
  font-size: .9em;
}
#val_tag{
  margin-top: -2rem;
  font-size: var(--space-s);
  text-align: left;
  margin-left: 0%;
  color: black;
  font-weight: 700;
}



#fund_line{
  width: 120px;  
  margin: 0 0 4% 0;
  border: 2px solid #273195;
}

.banner-mobile{
  display: none;
}

.fund_flex{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 3%;
  margin-left: 0%;
}

.fund_img{
  margin-top: 4rem;
  height: 7rem;
  margin-left: -20rem;
}

.startind{
  margin-top: 8%;
  width: 18rem;
  height: 7rem;
}

.starttn{
     margin: 8% 8% 2% 8%;
  width: 19rem;
  height: 7rem;
  
}

.makeindia{
  margin-top: 4rem;
  height: 7rem;
  margin-left: -15rem;
}

.crescent{
  margin-top: 4rem;
  height: 7rem;

}

.gif{
  margin-left: 18%;
 margin-top: 3%;
  border-radius: 10px;
  width: 60%;
}


}






@media (min-device-width:768px)and (max-device-width:1024px){
  


#val_img{
 
  margin-left: 2rem;
  margin-top: 2em;
  width: 40%;
}
.val_cust_content{
  margin-top: 1em;
}
#val_tag{
font-size: 1.2em;
width: 88%;
margin-bottom: 2%;
}
.val_cust_content p{
  font-size: .9em;
  width: 84%;
  margin-bottom: 1em;
} 
#blue-line{
  margin: 0 auto;
  margin-top: 6px;
}


.careers{
  margin-top: -20%;
}

#careers_img{
  width:48%;
}
#career_card{
  margin: 3%;
  margin-left: 3%;
  width: 48%;
  }
  #career_title1{
    margin-top: 3%;
  }
  #career_line{
    margin: 0 auto;
    margin-top: 2%;
  }
  #career_title2{
    font-size: 2em;
  }
  #careers_content{
    font-size: 1em;
    margin-top: 2%;
    margin-bottom: 1em;
    line-height: 24px;
  }


  #cust_img{
    width: 50%;
    margin: 1% 2% 0 5%;
  }

  #val_content{
    margin-top: 10%;
  }

  .fund_flex{
    margin-top: -8%;
    margin-left: 0%;
  }
  .fund_img{
    width: 40%;
    margin-left: -2%;
  }

.startind{
  margin-top: 8%;
  width: 18rem;
  height: 7rem;
}

.starttn{
     margin: 8% 8% 2% 8%;
  width: 19rem;
  height: 7rem;
  
}

.makeindia{
  margin-top: 6rem;
  height: 6rem;
  margin-left: 0%;
}

.crescent{
  margin-top: 4rem;
  height: 7rem;

}

  #veltech_img{
    width: 40%;
    margin-left: 0;
  }
  #backed_by{
    font-size: 1.3em;
    margin-left: 20px;
  }


  #banner_title{
    font-size: 2em;
    margin: 5% 0 1% 2%;
  }
  #tagline{
    font-size: 1em;
    margin-top: 3em;
  }
  #banner_btn{
    margin-top: -20%;
  }
  #prod_title_tag{
    width: 90%;
    margin: 0 auto;
    
  }
  #back{
   margin-left: 200px;
    color: #0F2492;
  }
  
.card34_title{
  font-weight: 700;
  font-size: .5em;
}
.card{
  width: 24%;
}
.fund_flex img{
  width: 60%;
}

  
#space{
  width:"2000px";
  height:"12rem";
}

}