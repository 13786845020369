

#ctitle_1{
  text-align: center;
  padding-top: .8em;
  font-size: var(--space-m);
  
}

#ctag{
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

#csee_all{
  text-align: center;
  color: #0F2492;
  font-size: 1.5em;
  margin-top: 3em;
}

#csee_line{
  width: 37px;
  text-align: center;
  margin-top: -.9em;
border: 1.5px solid #0F2492;
}

#last_title{
    text-align: center;
    padding-top: 2%;
    margin-top: 5%;
}

#last_img{
   margin-left: 16%;
   
}

.section3{
    display: flex;
    flex-direction: row;
    padding-bottom:4%;
    margin-top: 3%;
}

#last_sec_content{
    margin-left: 8%;
    margin-top: 2%;
    padding-bottom: 5%;
}



#section3_p{
    width: 70%;
    font-weight: 500;

}


.cbanner_tag {
    position: absolute;
    top: 40%;
   
    float: left;
    margin-left: 3%;
    color: white;
    font-family: "Poppins";
    letter-spacing: 1.8px;
  }
  
  #cbanner_h1 {
    position: absolute;
    top: 46%;
    float: left;
    font-size: var(--space-m);
    margin-left: 3%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 56px;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media only screen and (min-device-width: 481px) {
  #cbanner_p {
      position: absolute;
      top:72%;
      margin-left: 3%;
    width: 52%;
    font-family: "Poppins";
    font-weight: 800;
    line-height: 2em;
    font-size:1.3em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #cbanner_soln_mobile{
    display: none;
  }

  #cbanner_soln {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.cbackground-grey{

    width: 100%;
    height: auto;
    background-color:  #f9f9f9;;
}

















@media only screen and (max-device-width: 480px) {

  #cbanner_soln{
 display: none;
  }

 
.section3{
  display: flex;
  flex-direction: column;
  padding-bottom:4%;
  margin-top: 3%;
}

#last_img{
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
 .cbanner_tag{
   
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     margin-top: -16em;
     font-size: .8em;
 }
    #cbanner_soln_mobile{
      width: 100%;
    
}

  #cbanner_h1{
    margin-top: -7em;
    line-height: 1.4em;
    font-size: 1.7em;
    margin-left: 1em;
  }

  #cbanner_p{
     position: relative;
     top: -15vh;
      text-align: center;
            font-size: .9em;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: 500;
      margin-left: 1em;
      color: #ffffff;
      line-height: normal;
  
}

#ctag{
  text-align: center;
  font-weight: 500;
  
  margin: 3%;
  
}

#ctitle_1{

  font-size: 1.5em;
  text-align: center;
}

#csee_all{
  font-size: 2em;
  margin-top: 0;
  
}

#csee_line{
 
  text-align: center;
  color: #0F2492;
  font-size: 1.5em;
 width: 2em;
}
.cbackground-grey{

  
  width: 100%;
  height: auto;
  background-color:  #f9f9f9;;
}

#section3_p{
  width: 96%;
  font-weight: 500;

}


}


@media (min-device-width:768px)and (max-device-width:1024px){
  .cbanner_tag{
    margin-top: -16%;
    font-weight: 500;
    font-size: .9em;
  }
  #cbanner_h1{
    margin-top: -15%;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.5em;
  }
  #cbanner_p{
    font-size: .9em;
    line-height: 1.4em;
    font-weight: 500;
    margin-top: -20%;
    width: 86%;
  }
  #ctitle_1{
    font-size: 2em;
  }
  #ctag{
    font-size: .9em;
    margin-top: 1%;
    margin-bottom: 2%;
  }
  .cbackground-grey{  
    width: 100%;
    padding-bottom: 2em;
  }
  #last_img{
    width: 100%;
    margin: 10% 0 0 15%;
  }
  #last_sec_content{
    margin: 4% 0 0 10%;
  }
  #section3_p{
    font-size: .8em;
  }
}