#about_b_h{
    position: absolute;
    top: 46%;
     margin-left: 35%;
    text-align: center;
    font-size: var(--space-m);
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 56px;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#ben_img{
    border-radius: 10px;
}

#about_p{
 position: absolute;
 top: 70%;
 width: 60%;
 text-align: center;
 margin-left: 19%;
font-family: "Poppins";
font-weight: 800;
line-height: 2em;
font-size: var(--space-xs);
color: #ffffff;
}

.worldclass{
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


#w_content{
    margin-top: 2em;
    width: 40%;
}

#w_p{
    font-size: var(--space-1xs);
    line-height: 1.7em;
    font-weight: 600;
}

#h_quality{
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 3%;
}

.products_solutions{
    margin-top: 4%;
}

#benefit_title{
    text-align: center;
    margin-top: 3%;
}










@media only screen and (min-device-width: 482px){

#mortar_mobile{
    display: none;
}

}












@media only screen and (max-device-width: 480px) {
    #about_b_h{
        position: absolute;
        text-align: center;
        top: 15%;
        margin-left: 10%;
        line-height: 1.3em;
    }

    #about_p{
        position: absolute;
 top: 28%;
 width: 90%;
 text-align: center;
 margin-left: 2.5%;
 font-size: var(--space-1xs);
 font-weight: 500;
 line-height: 1.4em;
 font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
    }

    .worldclass{
        display: flex;
        flex-direction: column;
       
    }

    #w_img{
        width: 94%;
        padding-top: 3%;
        align-self: center;
    }

    #w_h2{
        font-size: medium;
        width: 200%;
        margin-left: 2em;
        margin-top: 0;
    }

    #w_p{
        margin-top: 0;
        font-size: var(--space-1xs);
        line-height: 1.7em;
        font-weight: 600;
        width: 200%;
        margin-left: 20%;
    }

    #h_quality{
        display: flex;
        flex-direction: column;
    }
    
    #benefit_title{
        font-size: large;
        width: 94%;
        margin-left: 2%;
       align-self: center;
    }

    #mission_img{
        width: 94%;
        align-self: center;
        border-radius: 10px;
    }

    #mortar_web{
        display: none;
    }

    
    #mortar_mobile{
        width: 94%;
        align-self: center;
    }
}




@media (min-device-width:768px)and (max-device-width:1024px){
    #about_b_h{
        position: absolute;
        top: 30%;
        margin-left: 24%;
        
        font-size: var(--space-m);
       
    }
    
#about_p{
    position: absolute;
    top: 40%;
    width: 80%;
    font-weight: 500;
    text-align: center;
    margin-left: 10%;
}

#w_img{
    width: 44%;
    padding-top: 0%;
    align-self: center;
}

#w_content{
    margin-top: 1em;
    width: 50%;
    margin-left: 1%;
    margin-right: 1%;
}
#w_h2{
    font-size: 1.2em;
}
#w_p{
    font-size: .8em;
}
#prod_soln_title{
    padding-top: 1em;
}
.card34_title{
    font-size: .8em;
}
.card p{
    font-size: .7em;
    font-weight: 400;
    padding-bottom: 1em;
}
#mission_img{
    width: 50%;
    margin-left: 1%;
}
#mortar_web{
    width: 50%;
    margin: 1%;
}
#ben_img{
    width: 50%;
    margin: 1%;
}
}