#ps_banner_p{
    position: absolute;
    top: 64%;
    margin-left: 3%;
  width: 65%;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 2em;
  font-size:1.2em;
  color: #ffffff;
}

#psblue-card-title{
    color: white;
    font-size: 1.2em;
    padding-left: 6%;
    padding-top: 4%;
}
#airless_grey{
    background-color: #f9f9f9;
    width: 100%;
}

#pic-cont{
    margin-left: 8%;
    padding-top: 4%;
    padding-bottom: 4%;
    display: flex;
    justify-content: space-around;
}

#img-cont-p{
    width: 75%;
    line-height: 1.7em;
}

#pic-cont img{
    width: 90%;
    height: 75%;
}

#content-r{
    margin-left: 6%;
}

#cont3{
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    padding-bottom: 4%;
}

#subcont{
    width:25%;
}

#subcont p{
    font-weight: 500;
    line-height: 1.7em;
}

#machines{
    display: flex;
    justify-content: space-around;
    margin-top: 4%;
    margin-bottom: 4%;
}


.pslast_section{
    display: flex;
    flex-direction: row;    
    padding-bottom: 6%;
    align-self: center;
    width: 100%;
    justify-content: center;
}


#psblue_btn{
    background-color: #fafafa;
    width: 12em;
    font-size: var(--space-1xs);
    font-weight: 600;
    color: #253295;
    border: none;
height: 2.4em;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
margin-left: 10%;
margin-top: 6%;
cursor: pointer;
}

.psblue-card{
    background-color: #253295;
    height: 62vh;
    width: 40%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
} 

#why_spray_title{
    text-align: center;
}

#text-icon{
 
    display: flex;
    flex-direction: row;
}

#icon-img{
   margin-top: 1em;
   margin-left: 1%;
    height: 100%;
}























@media only screen and (max-device-width:480px) {
    
    
#ps_banner_p{
    position: absolute;
    top: 28%;
    margin-left: 5%;
  width: 80%;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.4em;
  font-size:.9em;
  color: #ffffff;
}

#pic-cont{
    margin-left: 8%;
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#img-cont-p{
    width: 90%;
    line-height: 1.7em;
    margin-top: 1em;
}

#pic-cont img{
    width: 90%;
    height: 75%;
}

#content-r{
    margin-left: 6%;
    margin-top: 1em;
}

#cont3{
    display: flex;
    justify-content:center;
    flex-direction: column;
    margin-top: 4%;
    padding-bottom: 4%;
}

#subcont{
    width:80%;
    margin-left: 10%;
    margin-top: 2.5em;
}

#subcont h2{
    width:100%;
    text-align: center;
    font-size: large;
}

#subcont p{
    font-weight: 500;
    line-height: 1.7em;
}

#machines{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
}



.pslast_section{
    display: flex;
    flex-direction: column;    
    padding-bottom: 6%;
    align-self: center;
    width: 100%;
    justify-content: center;
}


#psblue_btn{
    background-color: #fafafa;
    width: 12em;
    font-size:2em;
    font-weight: 600;
    color: #253295;
    border: none;
height: 2.4em;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
margin-left: 24%;
margin-top: 6%;
}

.psblue-card{
    background-color: #253295;
    height: 48vh;
    margin-left: 6%;
    width: 90%;
    border-radius: 10px 10px 0 0;
    border-top-left-radius: 10px;
} 
#psblue-card-title{
    line-height: 30px;
        letter-spacing: 0.2px;
        margin-left: 3% ;
        margin-top: 11%;
        color: #FFFFFF;
}
#blue-img{
    width: 90%;
    margin-top: 0;
    margin-left: 6.1%;
    border-radius: 0 0 10px 10px;
}
#why_spray_title{
    text-align: center;
}

#icon-img{
    height: 2em;
    margin-top: -5px;
    margin-left: -6em;
}

}



@media (min-device-width:768px)and (max-device-width:1024px){
    #ps_banner_p{
        margin-top: -18%;
        font-size: .9em;
        width: 80%;
    }
    #content-r{
        font-size: .9em;
        margin-left: 3%;
    }
    #pic-cont img{
        margin-top: 5%;
    }
    #cont3 div{
        width: 30%;
    }
    #subcont h2{
        font-size: 1em;
    }
    #subcont p{
        font-size: .9em;
        line-height: 1.4em;
        margin-top: 2%;
    }
    #machines img{
        width: 30%;
    }
    #blue-img{
        width: 50%;
        height: 280px;
    }
    #psblue-card-title{
        font-size: 1em;
        font-weight: 600;
        margin:2% ;
    }
    #blue-content{
        font-size: .9em;
        line-height: 1.4em;
    }
    #psblue_btn{
        font-size: .9em;
    }
    .psblue-card{
        height: 280px;
    }
    #why_spray_title{
        font-size: 1.5em;
    }
    #icon-img{
        margin-top: -13%;
        margin-left: 5%;
    }
    #subcont p{
        font-weight: 500;
        font-size: .9em;
    }
    #psblue_btn{
        font-size: .9em;
        width: 54%;
        height: 12%;
    }
}