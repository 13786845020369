#vbanner_h1{

    position: absolute;
    top: 18%;
    float: left;
    font-size: var(--space-s);
    margin-left: 4%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 2em;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .vbackground-grey{
       width: 100%;
    height: auto;
    padding-bottom: 2%;
    background-color:  #f9f9f9;
  }

  #vbanner_p {
      position: absolute;
      top: 60%;
      margin-left: 4%;
    width: 60%;
    font-family: "Poppins";
    font-weight: 800;
    line-height: 2em;
    font-size:1.1em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #vtitle_1{
    text-align: center;
    padding-top: .8em;
    width: 50%;
    margin: auto auto;
    font-size: var(--space-s);
  }

  #vtag{
    text-align: center;
    font-size: 1.1em;
    font-weight: 500;
  }

  #vsee_all{
    text-align: center;
    color: #0F2492;
    font-size: 1.5em;
    margin-top: 1em;
}

#always_img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 6%;
  margin-bottom: 3%;
  position: sticky;
}

#img{
  font-size: 1.2em;
  text-align: center;
  margin: 3%;
}

#content-3{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-left: 10%;
 margin-bottom: 4%;
}

#cont3-p{
  width: 68%;
  font-size: 1em;
  font-weight: 500;
}












@media only screen and (max-device-width: 480px) {
    #banner_soln_mobile{
      width: 100%;
    }
    
    #vtag{
      font-size: 1em;
      padding: 1em;
    }
    #vbanner_h1{

      position: absolute;
      top: 11%;
      float: left;
      font-size: var(--space-s);
      margin-left: 4%;
      color: white;
      font-weight: 900;
      font-family: "Poppins";
      line-height: 1.2em;
      letter-spacing: 0.2em;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    
    #vbanner_p {
        position: absolute;
        top: 28%;
        margin-left: 4%;
      width: 90%;
      font-family: "Poppins";
      font-weight: 800;
      line-height: 1.5em;
      font-size:.8em;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    #vtitle_1{
      text-align: center;
      padding-top: 1em;
      font-size: 1em;
      width: 90%;
      margin-bottom: 3%;
    }
    .vbackground-grey{
      margin-top:0%;
  
  width: 100%;
  height: auto;
  background-color:  #f9f9f9;;
    }
  
  #always_img{
    width: 80%;
    margin-top: 8%;
    margin-bottom: 6%;
  }

  #img{
    font-weight: 500;
    font-size: .9em;
    width: 92%;
  }
  #content-3{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #cont3-p{
    width: 90%;
    margin-bottom: 6%;
  }
}




@media (min-device-width:768px)and (max-device-width:1024px){
    #vbanner_p{
      font-size: 1em;
      font-weight: 500;
      margin-top: -15%;
      line-height: 1.5em;
      width: 80%;
    }
    #vtag{
      font-size: .9em;
      width: 90%;
      margin: 2% auto;
    }
    #img{
      font-size: 1em;
    }
    #content-3{
        justify-content: space-around;
        margin-left: 3%;
    }
    #cont3-p{
      font-size: .8em;
      margin-top: 2%;
      width: 90%;
    }
}