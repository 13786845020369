
*{
  scroll-behavior: smooth;
  

  font-family: 
  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@media only screen and (max-device-width:480px){
  #banner_mobile_hid{
    display: none;
  }
  hr{
    display: none;
  }
  .mobile_view{
    overflow-x: hidden;
  
    scroll-behavior: smooth;
  }
}