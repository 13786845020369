@media only screen and (max-device-width: 480px) {
  .container {
    width: 100%;
  }
  .banner {
    display: none;
  }
  #desktop_only{
display: none;
  }
  .banner-mobile {
    position: relative;
    top: 30px;
    width: 100%;
    z-index: -1;
  }

  .sprayx {
    position: absolute;
    top: 7%;
    left: 25%;
    width: 60%;
    z-index: -1;
  }

  #banner_title {
    display: none;
  }

  #tagline {
   display: none;
  }
    
  #back{
    margin-left: -29%;
  }
  
  #fund_line{
    display: block;
    width: 120px;  
    margin: 2% 0 10% -28%;
    border: 2px solid #273195;
  }
 

  #banner_btn {
    position: relative;
    width: 150px;
    height: 28px;
    top: -7vh;
    left: 6%;
    font-size: 1.3em;
    color: black;
    border: none;
    font-weight: 500;
    background: #f6f6f6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
  }


  .products_solutions {
    width: 100%;
    height: auto;
    background: linear-gradient(
        239.01deg,
        rgba(239, 239, 239, 0.2) 0.72%,
        rgba(255, 255, 255, 0) 68.76%
      ),
      linear-gradient(119.99deg, #e6e6e6 -0.53%, rgba(255, 255, 255, 0) 68.3%);
  }

  #prod_soln_title {
    padding-top: 2em;
    font-size: 20px;
    text-align: center;
  }


  .products_solutions p {
    font-size: var(--space-xs);
    margin: 2%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 450;
    text-align: center;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .card {
    width: 86%;
    margin-top: 2rem;

    margin: 3%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }
  .card:hover {
    transform: scale(1.08);
  }
  .card img {
    width: 94%;

    margin: 3%;
  }

  .products_solutions .card p {
    text-align: left;
    font-size: var(--space-xs);
    width: 90%;
    margin-top: 3%;
    margin-left: 5%;
    line-height: 28px;
    font-weight: 500;
    text-transform: none;
  }

  .card34_title {
    margin-top: 3%;
    font-size: var(--space-xs);
    text-align: center;
    color: black;
    font-weight: 700;
  }

  .card34_title_1 {
    margin-top: 3%;
    margin-left: 5%;
    font-size: var(--space-xs);
    text-align: center;
    color: black;
    font-weight: 700;
  }

  .values_customers {
    width: 100%;
    padding-top: 3em;
    padding-bottom: 3em;
    height: auto;
    align-items: center;
  }

  #val_img {

    width: 90%;
    margin: 5%;
  }

  #val_title {
    margin-top: 0px;
    font-size: var(--space-s);
    color: #0f2492;
    text-align: center;

  }
 #val_tag{
   margin-left: 5%;
 }
  #blue-line {
    width: 70px;
    border: 2px solid #273195;

  }

  .val_cust_content p {
    line-height: 1.6em;
    margin-top: 0em;
    padding-left: 6%;
    padding-right: 6%;
    font-weight: 500;
    font-size: var(--space-1xs);
    text-align: left;
  }

  .learn_more{
    color: #0f2492;
    margin-left: 9.5em;
    font-weight: 700;
    font-size: 1em;
  }

  #learn-blue-line {
    width: 70px;
    border: 2px solid #273195;
    
  }

  .val_cust_content h2 {
    margin-left: 5%;
  }

  .careers {
    margin-top: 2em;
    padding-top: 1.5em;
    width: 100%;
    height: auto;
    background: #efefef;
    padding-bottom: 4%;
  }

  #career_title1 {
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0f2492;
  }

  #career_title2 {
    margin-top: 46px;
    margin-left: 30px;
    font-size: 38px;
    text-align: left;
    letter-spacing: 4px;
  }

  #career_line {
    width: 100px;
    border: 2px solid #273195;
  }

  #career_card p {
    text-align: left;
    padding-left: 30px;
    width: 86%;
    font-size: 20px;

    line-height: 32px;
  }

  #career_card a {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #0f2492;
  }

  #career_learn{
    font-weight: 700;
    margin-left: 7em;
    color: #0F2492;
  }

  #careers_img {
    width: 90%;
    margin: 5%;
    border-radius: 10px;
  }

  #cust_img {
    width: 90%;
    margin: 5%;
  }

  .cust_learn_more{
    font-size: 1em;
    font-weight: 700;
    color: #0F2492;
    margin-left:9.5em;
  }

  #cust-blue-line{
      width: 70px;
 
  border: 2px solid #273195;
  }
  .val_cust_content p {
    line-height: 1.7em;
    margin-top: 0em;
    font-weight: 500;
    font-size: var(--space-1xs);
    text-align: left;
  }

  .footer{
 
    width: 100%;
    height: 1290px;
    color: aliceblue;
    bottom: 0%;
    background-color: black;
    padding-left: 0;
}

.column{

  color: rgb(255, 255, 255); 
  margin-top: 2em;
}

.space{
  height: 20px;
  width: 100%;
  background-color: black;
}

#company{
  margin-top: 0em;
}
.column h2{
    margin-top: -2em;
    font-size: 24px;
    text-align: center;
}

.column a{
    font-size: 1em;
    font-weight: 500;
    margin-top: 1em;
    text-align: center;
}

.foot-logo{
  position: relative;
   left: 4.8em;
   bottom: -16em;
   height: 60px;
  
}

.foot-logo-1{
  position: relative;
  
   bottom: -15em;
  left: 5.5em;
  height: 30px;
  
 }


 #rights{
  position: relative;
  top:25em;
  
    color: #BABABA;
    font-size: 16px;
    text-align: center;
 }

 
   
 .flag{
  position: absolute;
  right: 9em;
  height: 60px;
  margin-top: 18em;
 }

 #flag_tag{
     color: white;   
     position: relative;
   left: 9em;
   bottom: -28em;
     font-size: 16px;
   
 }

 #prod_title_tag{
  text-align: center;
  font-size: 0.8em;
  padding: 2%;
 }

 .fund_flex{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 2%;
  padding-top: 2%;
  align-items: center;
}

.fund_img{
  margin-top:-3%;
  width: 60%;

}

.fund_flex img{

  margin-bottom: 4%;

 }

#backed_by{
  margin-top: 8%;
  font-size: 1.5em;
  text-align: center;
}

#veltech_img{
  height: 6rem;
  margin: 4%;
  width: 50%;
  margin-top:3%;
}


.startind{
  margin-top: 5%;
    width: 66%;
}

.starttn{
  width: 60%;
}

.makeindia{
  margin-top:0%;
  width: 56%;
}

.crescent{
  margin-top: 3%;
  width: 44%;
  height: 5.5rem;
}

}
