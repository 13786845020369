.Rh1 {
  text-align: center;
}

#sbanner_p{
    position: absolute;
    top: 60%;
    margin-left: 4%;
  width: 69%;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.4em;
  font-size:1.2em;
  color: #ffffff;
}

#first_cont {
  font-weight: 400;
  font-size: 1.1em;
  width: 86%;
  margin-left: 5%;
  margin-top: 1.5%;
}

.subheading {
  margin-left: 5%;
}

@media only screen and (min-device-width:480px) {
#rac {
  margin-left: 5%;
}


}





@media only screen and (max-device-width:480px) {

    .Rh1 {
        text-align: center;
        font-size: 1.2em;
      }

      #sbanner_p{
        position: absolute;
        top: 29%;
        margin-left: 3%;
      width: 90%;
      font-family: "Poppins";
      font-weight: 500;
      line-height: 1.4em;
      font-size:.9em;
      color: #ffffff;
    }
      
      #first_cont {
        font-weight: 400;
        font-size: 1em;
        width: 86%;
        margin-left: 5%;
        margin-top: 1.5%;
      }
      
      .subheading {
       
        font-size: 1.2em;
        text-align: center;
       
        
      }
      
      #rac {
        margin-left: -10%;
        width: 120%;
      }
      
      #img-cont-p{
          width: 100%;
          margin-left: -4%;
      }
}



@media (min-device-width:768px)and (max-device-width:1024px){
    #abanner_h1{
      font-size: 2em;
      line-height: 1.4em;
      margin-top: -1em;
    }
    #sbanner_p{
      margin-top: -16%;
      width: 80%;
    }
    #pic-cont img{
      width: 40%;
    }
    #img-cont-p{
      margin-top: 2%;
      font-size: .9em;
      line-height: 1.4em;
      width: 90%;
    }
    .Rh1{
      font-size: 1.5em;
    }
    #first_cont{
      font-size: .9em;
    }
    .subheading{
      font-size: 1.2em;
    }
    #first_cont li{
      font-size: 1.2em;
    }
    #rac{
      width: 94%;
    }
}