.terms{
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}


.introduction{
    text-align: center;
    font-weight: 800;
    color: #0d2366;

}

.headings{
    font-size: 1.5em;
    color: #0d2366;
}
main p{
    margin-top: 15px;
}

.parallel{
    display: flex;
    -ms-flex-align: start;
}

#l_content{
    width: 300%;
}
.quick_links{
    width: 100%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
}

.quick_links a{
    color: #0d2366;
    font-weight: 700;
    margin-bottom: 1.5em;
    font-size: 1em;
}




@media only screen and (max-device-width:480px){
    .quick_links{
        display: none;
    }
    .headings{
        font-size: 1em;
        color: #0d2366;
        margin-top: 6%;
        margin-bottom: 2%;
    }
    .introduction{
        margin-top: 2%;
    }
}




@media (min-device-width:768px)and (max-device-width:1024px){
.headings{
    font-size: 1.1em;
    color: #0d2366;
    margin-top: 14px;
    margin-bottom: 6px;
}
.terms p, ul{
    font-size:.8em;
}
.introduction{
    font-size: 1.5em;
}
.quick_links a{
    font-size: .8em;
}
}