#cont3-p-1{
    width: 88%;
    font-size: 1em;
    font-weight: 500;
}

#content-3-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

#diff_title{
    text-align: center;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

#sec-3-2nd{
    width: 30%;
    margin-left: 3%;
    padding-bottom: 4em;
}




@media only screen and (max-device-width: 480px)  {
    
#content-3-1{
    display: flex;
    flex-direction: column;
}

#tag{
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    padding: 1em;
}

#diff_title{
    text-align: center;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

#sec-3-2nd{
    width: 100%;
  
    padding-bottom: 2em;
}

#cont3-p-1{
    width: 92%;
    font-size: .9em;
    font-weight: 500;
    margin-top: 1em;
    margin-left: 2%;
    
}

#sec-3-subt{
    text-align: center;
    margin-top:1em ;
}

.ibackground-grey{
    height: auto;
    width: 100%;
    background-color:  #f9f9f9;;
}

.sec3_img{
    width: 94%;
}

}


@media (min-device-width:768px)and (max-device-width:1024px){
    #banner_h1{
        font-size: 2em;
        font-weight: 700;
        margin-top: -19%;
    }
    #banner_p{
        font-size: .9em;
        margin-top: -19%;
        line-height: 22px;
        width: 80%;
    }
    .sec3_img{
        width: 90%;
    }
    #sec-3-subt{
        font-size: 1em;
    }

    #cont3-p-1{
        font-size: .8em;
        margin-top: .4em;
        font-weight: 500;
        line-height: 1.2em;
    }
}