#abanner_p{
    position: absolute;
    top: 60%;
    margin-left: 4%;
  width: 69%;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.4em;
  font-size:1.2em;
  color: #ffffff;
}

#abanner_h1 {
    position: absolute;
    top: 25%;
    width: 60%;
    float: left;
    font-size: 3em;
    margin-left: 4%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 1.5em;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #acontent-r{
      margin-top: 3%;
      margin-left: 7%;
  }
  #aimg-cont-p{
      margin-top: 5%;
    width: 75%;
    line-height: 1.5em;
    font-size: 1.2em;
    font-weight: 400;
  }

  #black{
      background-color: rgb(29, 29, 29);
      color: #ffffff;
      text-align: center;
      justify-content: center;
      height: 7em;
      margin-bottom: 3%;
  }
 #black_title{
    align-self: center;
    padding-top:1em;
 }

 .list{
     display: flex;
     flex-direction: column;
 }

 .accessory{
    display: flex;
    flex-direction: row;
     padding-top: 1%;
     padding-bottom: 1%;
    margin-left: 10%;
 }

 .accessory p{
     width: 80%;
     line-height: 1.8em;
 }

 .acc_bline{
     width: 70px;
     border: 2px solid #273195;
     margin-left: 1%;
 }

 .acc_content{
     margin-left: 5%;
 }
 .acclearn_more{   

font-weight: 700;
font-size:   var(--space-1xs);
color: #0F2492;
 }

 .partition{
     width: 75%;
     color: #b6b6b6;
     margin-top: 2em;
 }

 .acc_pic{
    width: 25%;
 }














 




@media only screen and (max-device-width:480px) {
       
 .accessory{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
   margin-left: 10%;
   margin-top: 4%;
}


.acc_pic{
    width: 60%;
    margin-left: 10%;
    margin-top: 2em;

 }

 .acclearn_more{
    margin-top: 20px;
 }
 #abanner_h1 {
    position: absolute;
    top: 10%;
    width: 88%;
    float: left;
    font-size: 1.7em;
    margin-left: 3%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 1.4em;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  #abanner_p{
    position: absolute;
    top: 29%;
    margin-left: 3%;
  width: 90%;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.4em;
  font-size:.9em;
  color: #ffffff;
}

    #onepass_h2{
        font-size: medium;
    }

    #aimg-cont-p{
        margin-top: 5%;
      width: 90%;
      line-height: 1.5em;
      font-size: 1em;
      font-weight: 400;
    }

    #black{
        background-color: #272727;
        color: #ffffff;
        text-align: center;
        justify-content: center;
        height: 4em;
        margin-bottom: 3%;
    }
   #black_title{
      align-self: center;
      padding-top:1em;
      font-size: large;
   }
   #acontent-r{
    margin-left: 0;
   }
   .partition{
    width: 75%;
    color: #b6b6b6;
    margin-top: 2em;
}
}


@media (min-device-width:768px)and (max-device-width:1024px){
    #abanner_p{
        width: 80%;
        margin-top: -15%;
        line-height: 1.2em;
    }
    #title_1{
        font-size: 1.6em;
    }
    #tag{
        font-size: .9em;
        margin: 1%;
    }
    #acontent-r{
        margin-top: 0;
    }
    #onepass_h2{
        font-size: 1.2em;
    }
    #aimg-cont-p{
        font-size: .9em;
        width: 90%;
    }  
   
    .list{
      margin-left: 0;
    }
    .acc_pic{
        width: 90%;
    }
    .acc_content{
        font-size: .9em;
        width: 100%;
    }
    .partition{
        margin: 0 auto;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .acc_content p{
        margin-bottom: 2%;
    }
}
