@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


@media (min-device-width:1024px) {
.mob_header{
  display: none;
}
}


@media (min-device-width:768px) and (max-device-width:1024px){
  .nav-area{
    display: none;
  }
  .hamburger img{
    padding-top: 10px;
  }
  .close img {
    position: absolute;
   left : 92%;
    width: 34px;
    height: 34px;
  }
  .mob_header{
    height: 64px;
  }
}

@media only screen and (max-device-width:1024px) {
  
  *{
   margin:0;
    padding: 0;
 
    /* border: 1px solid red; */
  }

  #logo_hmob{
    width: 175px;
    margin-left: 2%;
    margin-top: 2.5%;
    padding-bottom: 2%;
  }
 
  .hamburger, .close {
    border: none;
    cursor: pointer;
    /* position absolute position the icons relative to the body because they have no position relative parents*/
 float: right;
    margin-top: 25px;
    margin-right: 20px;
    width: 34px;
    height: 34px;
    
  }
  li a{
    font-size: .5em;
  }
  
  .close {
    background: rgb(255, 255, 255);
  }
  
  .hamburger img, .close img {
    /* width: 100%;
    height: 100%; */
    float: right;
    margin-right:0;
    margin-top: -12px;
    width: 34px;
    height: 34px;
  }
  
  .close img {
    float: right;
    margin-right:-11rem;
    margin-top: -7px;
    width: 34px;
    height: 34px;
  }
  .navbar {
    position: absolute;
    /* a higher z-index put navbar above hamburger */
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* basic menu styling*/
    list-style: none;
    background: white;
    display: flex;
    flex-flow: column nowrap;
    
    align-items: center;
    /* animate slide up/down */
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  /* :target is called when its anchor id #navbar is called by clicking on the hamburger which has href="#navbar" */
  
  .navbar:target {
    /* show navbar */
    transform: translateY(0);
  }
  
  li a {
    display: block;
    font-family: 'Open Sans', sans-serif;
    background: linear-gradient(90.03deg, rgba(27, 94, 250, 0.85) 1.72%, rgba(117, 12, 246, 0.85) 55.97%, rgba(227, 66, 6, 0.85) 102.22%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1.1rem;
    /* remove default underline and add our own with padding and border bottom */
    text-decoration: none;
    text-align: center;

    padding-bottom: 0.5rem;
  }
  
  li a:hover, li a:focus {
    /* show border bottom */
    border-bottom: 1px solid white;
  }
}