.footer{
 
    width: 100%;
    color: white;
    margin-bottom: -20px;
    background-color: black;
}

.row{
    display: grid;
    grid-template-columns: repeat(auto-fill, 
                           minmax(300px, 1fr));
    grid-gap: 8%;
    color: white;
    
}

.column{
  display: flex;
  flex-direction: column;
  text-align: left;
  color: rgb(255, 255, 255);
  padding-left: 9%;

}

.column h2{
    padding-top: 1em;
    font-size: 26px;
}

.column a{
    font-size: 1em;
    font-weight: 600;
    margin-top: 1em;
    color: white;
}

.foot-logo{
   position: absolute;
   left: 4em;
   height: 60px;
   margin-top: -7em;
}

.foot-logo-1{
    position: absolute;
    left: 8em;
    height: 36px;
    margin-top: -6em;
 }

 .foot-logo1-mobile{
     display: none;
 }

 .foot-logo-mobile{
    display: none;
 }
 #rights{
     margin-top: 5em;
    color: #BABABA;
    font-size: 19px;
    text-align: center;
 }

 .flag{
   position: absolute;
   right: 10em;
   height: 70px;
   margin-top: -10em;
 }

 #flag_tag{
    color: white;
    position: absolute;
    right:10.35em;
    font-size:1em ;
    margin-top:-5em;
}


@media (min-device-width:768px)and (max-device-width:1024px){
    .support{
        margin-top:-44%;
    }
    .bottom{
        margin-top: 30%;
    }
    .foot-logo{
        width: 5%;
    }
    .foot-logo-1{
        width: 20%;
    }
    .banner{
        height: 1000px;
        background-size: cover;
    }
}