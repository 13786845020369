#join{
    font-weight: 900;
    position: absolute;
    top: 76%;
    left: 10%;
font-size: 4em;
letter-spacing: 0.01em;

color: #FFFFFF;
}

.parallel p{
    font-size: 1em;
    line-height: 1.5em;
}
#carbanner_p{
    position: absolute;
    top: 68%;
    margin-left: 3%;
  width: 70%;
  font-family: "Poppins";
  font-weight: 800;
  line-height: 2em;
  font-size:1em;
  color: #ffffff;
}

#carbanner_h1{
    position: absolute;
    top: 36%;
    float: left;
    font-size: var(--space-m);
    margin-left: 3%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 56px;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#btn_center{
    text-align: center;
    padding-bottom: 3%;
}

#job_btn{
      background-color: #0b30c6;
    color: white;
    font-size: 1.2em;
    font-weight: 500;
    padding: 1%;
    border: none;
    border-radius: 18px;
    cursor: pointer;
}

#apply_btn{
    background-color: #0b30c6;
    color: white;
    font-size: 1em;
    font-weight: 400;
    padding: .5%;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

#desc_btn{
    background-color: #0b30c6;
    border-radius: 6px;
    padding: 1%;
    color: white;
    font-weight: 500;
    font-size: 1em;
    border: none;
    cursor: pointer;
    margin-bottom: 3%;

}
#title_right{
    margin-left: -100%;
}

.right_flex{
    margin-right: 24%;
}
#job_btn:hover{
    background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 34.85%, #662DE4 102.54%);
}

#center{
    text-align: center;
    font-size: 1.6em;
}

#cartag{
    width: 70%;
    margin-left: auto;
    font-size: 1.1em;
    margin-right: auto;
    text-align: center;
    font-weight: 500;
}

#ash{
    background-color: #f9f9f9;
}

#ash_tag{
    text-align: center;
    font-size: 1.3em;
    padding-top: 2em;
}

#ash_title{
    text-align: center;
letter-spacing: 0.2px;
font-size: 3em;
background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 34.85%, #662DE4 102.54%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}

.flx{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 5%;
}

.divcont{
    width: 40%;
}

#red-text{
    
background: linear-gradient(90.03deg, rgba(243, 97, 25, 0.85) 1.72%, rgba(246, 12, 86, 0.85) 51.97%, rgba(247, 86, 28, 0.85) 102.22%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}

.shared_title{
    font-size: 3.3em;
    text-align: center;
}

#open_title{
    text-align: center;
    margin-top: 4%;
}

.flx_open{
    display: flex;
    flex-direction: row;
    margin-bottom: 3%;
    justify-content: space-evenly;
}

.role_card{
width: 20%;
height: 7rem;
background: #FDFDFD;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.role_blue{
  text-align: center;  
background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 80.15%, #662DE4 102.54%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
 font-size: 1.5em;
}

#viewj{
    color: #1F3898;
    text-align: center;
    font-weight: 500;
    font-size: .8em;
    margin-left: 34%;
    margin-right: 32%;

}

#jdb_h{
    position: absolute;
    top: 20%;
     margin-left: 35%;
    text-align: center;
    font-size: var(--space-m);
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 56px;
    letter-spacing: 0.2em;
    text-shadow: 9px 8px 8px rgba(0, 0, 0, 0.5);
  
}

#jdb_p{
    position: absolute;
    top: 40%;
    width: 80%;
    margin-left: 10%;
    text-align: center;
    font-size: var(--space-1xs);
    color: white;
    font-weight: 500;
    font-family: "Poppins";
  
    letter-spacing: 0.1em;
    text-shadow: 9px 8px 8px rgba(0, 0, 0, 0.5);
}

#jd_tag{
    text-align: center;
    width: 70%;
    margin-left: 15%;
}

#apply_btn{
    width: 6rem;
    background-color: #2961FF;
    color: white;
    border: none;
    font-size: medium;
    padding: 1% 2% 1% 2%;
    border-radius: 10px;
    margin-left: 45%;
    margin-right: 30%;
    margin-bottom: 3%;
    margin-top: 3%;
    align-self: center;
}


.quick_job_links{
    width: 100%;
    margin-left: -4%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
}


.quick_job_links a{
    color: #0d2366;
    font-weight: 700;
    margin-bottom: 1.5em;
    font-size: 1em;
}















@media only screen and (min-device-width:481px){

#carbanner_h1{
    display: none;
}

#join{
    display: none;
}

.quick_job_links_mob{
    display: none;
    width: 100%;
}

.parallel_j{
    display: flex;
    -ms-flex-align: start;
}





}












































@media only screen and (max-device-width:480px){
    #join{
        font-weight: 900;
        position: absolute;
        top: 40%;
        left: 18%;
        text-align: center;
    font-size: 2em;
    letter-spacing: 0.01em;
    
    color: #FFFFFF;
    }

    #job_btn{
        background-color: #0b30c6;
      color: white;
      font-size: 1.5em;
      font-weight: 500;
      padding: 3%;
      border: none;
      margin-top: 3%;
      border-radius: 10px;
      cursor: pointer;
  }
    
    #desc_btn{
        margin-top: 3%;
        padding: 2%;
        font-size: 2em;
    }
.parallel_j{
    display: block;
}

    .quick_job_links{
        display: none;
    }
    .quick_job_links_mob{
      text-align: center;
    
    }
#apply_btn{
    margin-left: 35%;
    margin-right: auto;
}

    
.quick_job_links_mob a{
    color: #0d2366;
    font-weight: 700;
    margin-bottom: 1.5em;
    line-height: 1.5em;
    font-size: 1em;

}

    #carbanner_p{
        position: absolute;
        top: 26%;
        margin-left: 3%;
      width: 90%;
      font-family: "Poppins";
      font-weight: 800;
      line-height: 1.3em;
      font-size:1em;
      color: #ffffff;
    }
    
    #carbanner_h1{
        position: absolute;
        top: 14%;
        float: left;
        font-size: var(--space-m);
        margin-left: 3%;
        color: white;
        font-weight: 900;
        font-family: "Poppins";
        line-height: 56px;
        letter-spacing: 0.2em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    
    
#ash_tag{
    margin-left: auto;
margin-right: auto;
    font-size: 1em;
    width: 90%;
}

#ash_title{
    text-align: center;
letter-spacing: 0.2px;
width: 90%;
margin-left: auto;
margin-right: auto;
font-size: 2em;
background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 34.85%, #662DE4 102.54%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}
.flx{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 5%;
}

.divcont{
    width: 90%;
    margin-left: auto;
margin-right: auto;
}

.shared_title{
    font-size: 2em;
    width: 90%;
    margin-left: auto;
margin-right: auto;

}
#cartag{
    width: 90%;
}



#open_title{
    text-align: center;
    margin-top: 4%;
    font-size: larger;
}

.flx_open{
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    justify-content: space-evenly;
}

.role_card{
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 4%;
height: 5rem;
background: #FDFDFD;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.role_blue{
  text-align: center;  
background: linear-gradient(270deg, #57AEDE 10.05%, #6A3FD1 80.15%, #662DE4 102.54%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
 font-size: 1.3em;
 padding-top: 3%;
 padding-bottom: 2%;
}

#viewj{
    color: #1F3898;
    text-align: center;
    font-weight: 500;
    font-size: 1em;
    margin-left: auto;
    margin-right: auto;

}

.parallel_j h2{
    font-size: 1.3em;
}



}





@media (min-device-width:768px)and (max-device-width:1024px){
#ash{
    margin-top: 2%;
}
#ash_title{
    font-size: 2.5em;
}
#ash_tag{
    font-size:1.1em;
    text-align: center;
    width: 100%;
}
.divcont h1{
    font-size: 1.7em;
    margin: 2% 0 2% 0;
}
.shared_title{
    font-size: 3em;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2%;
}
#cartag{
    font-size: 1em;
}
#job_btn{
    margin-top: 1em;
    font-size: 1em;
}
}





@media (min-device-width:768px)and (max-device-width:1024px){

    .quick_job_links a{
        font-size: .9em;
    }

    .quick_job_links{
    margin-left: 5%;
    }
   
    ul{
        margin:1% 0 2% 0;

    }
    .content-p h4{
        margin-bottom: 2%;
        margin-top: 3%;
    }
    
    .content-p ul li{
        font-size: 13px;
        font-family: sans-serif;
        line-height: 16px;
    }
    #desc_btn{
        margin-top: 2%;
        font-size: .9em;
        padding: 1.5%;
    }
}