
#banner_soln {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .banner_tag {
    position: absolute;
    top: 40%;
   
    float: left;
    margin-left: 3%;
    color: white;
    font-family: "Poppins";
    letter-spacing: 1.8px;
  }
  
  #banner_h1 {
    position: absolute;
    top: 46%;
    float: left;
    font-size: var(--space-m);
    margin-left: 3%;
    color: white;
    font-weight: 900;
    font-family: "Poppins";
    line-height: 56px;
    letter-spacing: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  #banner_p {
      position: absolute;
      top: 72%;
      margin-left: 3%;
    
    font-family: "Poppins";
    font-weight: 800;
    line-height: 2em;
    font-size:1.2em;
    color: #ffffff;
  }
  
  #title_1{
      text-align: center;
      padding-top: .8em;
      font-size: 2em;
      padding: 1em;
  }
  
  #tag{
      text-align: center;
      font-size: 1em;
      font-weight: 500;
  }
  
  #see_all{
      text-align: center;
      color: #0F2492;
      font-size: 1.5em;
      margin-top: 2em;
  }
  
  #see_line{
      width: 37px;
      text-align: center;
      margin-top: -.9em;
  border: 1.5px solid #0F2492;
  }
  
  .background-grey{
      width: 100%;
      height: 134vh;
      background-color:  #F9F9F9;;
  }
  .card-image{
      width: 94%;
      margin: 3%;
    } 
  
  .card34_title{
      text-align: left;
      margin-left: 5%;
  } 
  .last_section{
      display: flex;
      margin-top: 3%;
      flex-direction: row;    
      padding-bottom: 6%;
  }
  .blue-card{
      background-color: #253295;
      height: 62vh;
      width: 40%;
      margin-left: 10%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
  }  
  
  @media only screen and (min-device-width: 481px) {
  #banner_soln_mobile{
     display: none;
  } 
  #spray-tip-mobile{
      display: none;
  }
  
  #banner_p {
      position: absolute;
      top: 72%;
      margin-left: 3%;
      width: 50%;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 2em;
    font-size: var(--space-1xs);
    color: #ffffff;
  }
   
  #yellow_text{
  margin-left: 11%;
  font-weight: 500;
  margin-top: 12%;
  color: #FFC107;
  }
  
  #blue-card-title{
      line-height: 30px;
      letter-spacing: 0.2px;
      margin-left: 10% ;
      margin-top: 11%;
      color: #FFFFFF;
  }
  
  #blue-content{
      color: white;
      margin-left: 10%;
      margin-top: 6%;
      line-height: 1.8em;
  }
  
  #blue_btn{
      background-color: #fafafa;
      width: 6em;
      font-size: var(--space-1xs);
      font-weight: 600;
      color: #253295;
      border: none;
  height: 2.4em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 10%;
  margin-top: 6%;
  cursor: pointer;
  }
  
  
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media only screen and (max-device-width: 480px) {
  
      #banner_soln{
     display: none;
      }
      #banner_mobile_hid{
              z-index: -1;
      }
      .banner_tag{
          margin-top: -16.5em;
          font-size: .8em;
       
      }
      #banner_soln_mobile{
              width: 100%;
              z-index: -30;
      } 
      #banner_h1{
          margin-top: -8.2em;
          line-height: 1.4em;
          margin-left: 1em;
         
      }
  
      #banner_p{
          text-align: center;
          font-size: .8em;
          margin-left: 0em;
          margin-top: -20em;
          line-height: normal;
        
      }
  
      
  #title_1{
      text-align: center;
      padding-top: .8em;
      font-size: 1em;
  }
      #see_all{
          margin-top: 0em;
      }
  
      .card_content{
              margin: 3%;
              font-weight: 500;
              font-size: 1em;
              text-transform: none;
      }
  
      
  .background-grey{
      width: 100%;
      height: auto;
      background-color:  #f9f9f9;;
  }
  
   
  .blue-card{
      background-color: #253295;
      height: 46vh;
      width: 94%;
      border-radius: 10px;
      margin-left: 3%;
      z-index: -1;
  }  
  
  .last_section{
      display: flex;
      flex-direction: column;    
      padding-bottom: 4%;
  }
  
  #spray-tip-image{
      display: none;
  }
  
  
  #yellow_text{
      margin-left: 11%;
      font-weight: 500;
      margin-top: 12%;
      color: #FFC107;
      }
      
      #blue-card-title{
          line-height: 30px;
          letter-spacing: 0.2px;
          margin-left: 10% ;
          margin-top: 11%;
          color: #FFFFFF;
      }
      
      #blue-content{
          color: white;
          margin-left: 10%;
          margin-top: 6%;
          line-height: 1.8em;
      }
      
      #blue_btn{
          background-color: #fafafa;
          width: 6em;
          margin-top: 5%;
          margin-left:38%;
         margin-right:auto;
          font-size: 2em;
          font-weight: 600;
          color: #253295;
          border: none;
          cursor: pointer;
      height: 2.4em;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      
      }
      
      
  }
  

  @media (min-device-width:768px)and (max-device-width:1024px){
    #banner_h1{
        font-size: 2em;
        line-height: 1.4em;
        margin-top: 4%;
    }
    #carbanner_p{
        font-size: .9em;
        font-weight: 400;
        margin-top: -18%;
        line-height: 1.4em;
    }
    #title_1{
        font-size: 1.1em;
        margin-top: 3%;
        padding: 1%;
    }
    #tag{
        margin-top: 0;
    }
    .background-grey{
        height: auto;
        padding-bottom: 4%;
    }
    #spray-tip-image{
        height: 300px;
        width: 50%;
    }
    .blue-card{
        height: 300px;
    
    }
    #yellow_text{
        font-size: 1em;
        margin: 8% 0 0 8% ;
    }
    #blue-card-title{
        font-size: 1.2em;
        margin-top: 2%;
        margin-left: 8%;
    }
    #blue_btn{
        font-size: .9em;
        width: 25%;
        height: 10%;
    }
    .last_section{
        margin: 3% 8% 0 0;
    }
}
  
  
  