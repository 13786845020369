
  
  .accordion {
    width: 80%;
    margin: 2rem auto;
  }
  
  .accordion-item{
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin-top: 3%;
    transition:0.8s ease-in;

  }
  .accordion-title {
    font-size: 1.2em;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #eee;
 
  }
  
  .accordion-title:hover {
    background-color: rgb(193, 193, 193);
    border-radius: 10px;
  }
  
  .accordion-title,
  .accordion-content {
    padding: 1rem;
  
  }
  
  .accordion-content {
    background-color: #fbfbfb;
    transition:0.8s ease-in;
    white-space: pre-wrap;
  }

  .faq_grey{
    width: 100%;
    background-color: rgb(227, 227, 227);
    padding-top: 1.3%;
    padding-bottom: 1.3%;
    text-align: center;
    font-size: 1.5em;
  }
  
  #acco_contact{
    font-weight: 500;
    text-transform:inherit;
  }
  @media screen and (max-width: 700px) {
 
    .accordion {
      width: 90%;
   
    }
    #acco_contact{
      font-weight: 500;
      font-size: 1em;
        }
    .accordion-title,
  .accordion-content {
    font-weight: 500;
    transition: background-color .3s cubic-bezier(.25,.46,.45,.94);
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-delay: 0s;
  }
    .faq_grey{
        font-size: 1em;
    }
    .accordion-item{
        transition: background-color .3s cubic-bezier(.25,.46,.45,.94);
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition-delay: 0s;
    }
    .accordion-content {
     font-weight: 400;
     font-size: .8em;
    }
  }



  

@media (min-device-width:768px)and (max-device-width:1024px){
  .faq_grey{
    font-size: 1em;
}
}